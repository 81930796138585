import * as stylex from '@stylexjs/stylex';

/**
 * Color Tokens
 */
// const DARK_MODE = '@media (prefers-color-scheme: dark)';
// const DARK = '[data-theme="dark"]';
//
// export const color = stylex.defineVars({
//   bg: { default: '250, 250, 249', [DARK]: '28, 25, 23' },
//   textPrimary: { default: '28,25,23', [DARK]: '250,250,249' },
//   textInverse: { default: '250,250,249', [DARK]: '28,25,23' },
//   selectionBg: { default: '90,90,90', [DARK]: '250,250,249' },
//   selectionFg: { default: '250,250,249', [DARK]: '90,90,90' },
// });
const NEUTRAL0 = '255,255,255';
const NEUTRAL50 = '250,250,249';
const NEUTRAL100 = '165,161,159';
const NEUTRAL200 = '100,96,94';
const NEUTRAL400 = '68,65,62';
const NEUTRAL600 = '45,42,40';
const NEUTRAL800 = '28,25,23';
export const color = stylex.defineVars({
  surface0: NEUTRAL0,
  surface1: NEUTRAL50,

  border: NEUTRAL600,
  borderDim: NEUTRAL100,

  textInverse: NEUTRAL0,
  textPrimary: NEUTRAL800,
  textDim: NEUTRAL400,
  textXDim: NEUTRAL200,
  // textInverse: NEUTRAL50,

  /** Greens */
  accentATint: '94,155,124',
  accentA: '30,100,70',

  /** Reds */
  accentB: '135,20,20',
  accentBChromatic: '237, 1, 25',

  selectionBg: '90,90,90',
  selectionFg: '250,250,249',
});

const XL_TO_XXL = '@media all and (min-width:80em) and (max-width:101.9375em)';
const FROM_XXL = '@media all and (min-width:102em)';

// prettier-ignore
export const type = stylex.defineVars({
  // FONT SIZE TOKENS
  fzMin6: { default: '0.4375rem', [XL_TO_XXL]: '0.5rem', [FROM_XXL]: '0.5625rem' },
  fzMin5: { default: '0.5rem', [XL_TO_XXL]: '0.5625rem', [FROM_XXL]: '0.6875rem' },
  fzMin4: { default: '0.5625rem', [XL_TO_XXL]: '0.625rem', [FROM_XXL]: '0.75rem' },
  fzMin3: { default: '0.6875rem', [XL_TO_XXL]: '0.75rem', [FROM_XXL]: '0.875rem' },
  fzMin2: { default: '0.75rem', [XL_TO_XXL]: '0.875rem', [FROM_XXL]: '1rem' },
  fzMin1: { default: '0.875rem', [XL_TO_XXL]: '1rem', [FROM_XXL]: '1.125rem' },
  fz0: { default: '1rem', [XL_TO_XXL]: '1.125rem', [FROM_XXL]: '1.3125rem' },
  fz1: { default: '1.125rem', [XL_TO_XXL]: '1.3125rem', [FROM_XXL]: '1.5rem' },
  fz2: { default: '1.3125rem', [XL_TO_XXL]: '1.5rem', [FROM_XXL]: '1.75rem' },
  fz3: { default: '1.5rem', [XL_TO_XXL]: '1.6875rem', [FROM_XXL]: '2rem' },
  fz4: { default: '1.75rem', [XL_TO_XXL]: '1.9375rem', [FROM_XXL]: '2.3125rem' },
  fz5: { default: '2rem', [XL_TO_XXL]: '2.25rem', [FROM_XXL]: '2.625rem' },
  fz6: { default: '2.3125rem', [XL_TO_XXL]: '2.5625rem', [FROM_XXL]: '3rem' },
  fz7: { default: '2.625rem', [XL_TO_XXL]: '3rem', [FROM_XXL]: '3.4375rem' },
  fz8: { default: '3.0625rem', [XL_TO_XXL]: '3.4375rem', [FROM_XXL]: '4rem' },
  fz9: { default: '3.5rem', [XL_TO_XXL]: '3.9375rem', [FROM_XXL]: '4.5625rem' },
  fz10: { default: '4rem', [XL_TO_XXL]: '4.5rem', [FROM_XXL]: '5.25rem' },
  fz11: { default: '4.625rem', [XL_TO_XXL]: '5.1875rem', [FROM_XXL]: '6rem' },
  fz12: { default: '5.25rem', [XL_TO_XXL]: '5.9375rem', [FROM_XXL]: '6.9375rem' },
  fz13: { default: '6.0625rem', [XL_TO_XXL]: '6.8125rem', [FROM_XXL]: '7.9375rem' },
  fz14: { default: '6.9375rem', [XL_TO_XXL]: '7.8125rem', [FROM_XXL]: '9.125rem' },
  fz15: { default: '8rem', [XL_TO_XXL]: '9rem', [FROM_XXL]: '10.5rem' },
  // fz16: { default: '9.1875rem', [XL_TO_XXL]: '10.3125rem', [FROM_XXL]: '12.0625rem' },
  // fz17: { default: '10.5625rem', [XL_TO_XXL]: '11.875rem', [FROM_XXL]: '13.875rem' },
  // fz18: { default: '12.125rem', [XL_TO_XXL]: '13.625rem', [FROM_XXL]: '15.9375rem' },
  // fz19: { default: '13.9375rem', [XL_TO_XXL]: '15.6875rem', [FROM_XXL]: '18.3125rem' },
  // fz20: { default: '16rem', [XL_TO_XXL]: '18rem', [FROM_XXL]: '21rem' },
  // fz21: { default: '18.375rem', [XL_TO_XXL]: '20.6875rem', [FROM_XXL]: '24.125rem' },
  // fz22: { default: '21.125rem', [XL_TO_XXL]: '23.75rem', [FROM_XXL]: '27.6875rem' },
  // fz23: { default: '24.25rem', [XL_TO_XXL]: '27.3125rem', [FROM_XXL]: '31.8125rem' },
  // fz24: { default: '27.875rem', [XL_TO_XXL]: '31.3125rem', [FROM_XXL]: '36.5625rem' },
  // fz25: { default: '32rem', [XL_TO_XXL]: '36rem', [FROM_XXL]: '42rem' },
  // fz26: { default: '36.75rem', [XL_TO_XXL]: '41.375rem', [FROM_XXL]: '48.25rem' },
  // fz27: { default: '42.25rem', [XL_TO_XXL]: '47.5rem', [FROM_XXL]: '55.4375rem' },
  // fz28: { default: '48.5rem', [XL_TO_XXL]: '54.5625rem', [FROM_XXL]: '63.6875rem' },
  // fz29: { default: '55.6875rem', [XL_TO_XXL]: '62.6875rem', [FROM_XXL]: '73.125rem' },
  // fz30: { default: '64rem', [XL_TO_XXL]: '72rem', [FROM_XXL]: '84rem' },

  // LINE HEIGHT TOKENS
  lhMin6: { default: '0.5rem', [XL_TO_XXL]: '0.75rem', [FROM_XXL]: '0.75rem' },
  lhMin5: { default: '0.75rem', [XL_TO_XXL]: '0.75rem', [FROM_XXL]: '1rem' },
  lhMin4: { default: '0.75rem', [XL_TO_XXL]: '1rem', [FROM_XXL]: '1.25rem' },
  lhMin3: { default: '1rem', [XL_TO_XXL]: '1.25rem', [FROM_XXL]: '1.25rem' },
  lhMin2: { default: '1.25rem', [XL_TO_XXL]: '1.25rem', [FROM_XXL]: '1.5rem' },
  lhMin1: { default: '1.25rem', [XL_TO_XXL]: '1.5rem', [FROM_XXL]: '1.75rem' },
  lh0: { default: '1.5rem', [XL_TO_XXL]: '1.75rem', [FROM_XXL]: '2rem' },
  lh1: { default: '1.75rem', [XL_TO_XXL]: '2rem', [FROM_XXL]: '2rem' },
  lh2: { default: '2rem', [XL_TO_XXL]: '2rem', [FROM_XXL]: '2.25rem' },
  lh3: { default: '2rem', [XL_TO_XXL]: '2.25rem', [FROM_XXL]: '2.5rem' },
  lh4: { default: '2.25rem', [XL_TO_XXL]: '2.5rem', [FROM_XXL]: '3rem' },
  lh5: { default: '2.5rem', [XL_TO_XXL]: '2.75rem', [FROM_XXL]: '3.25rem' },
  lh6: { default: '3rem', [XL_TO_XXL]: '3.25rem', [FROM_XXL]: '3.5rem' },
  lh7: { default: '3.25rem', [XL_TO_XXL]: '3.5rem', [FROM_XXL]: '4rem' },
  lh8: { default: '3.75rem', [XL_TO_XXL]: '4rem', [FROM_XXL]: '4.5rem' },
  lh9: { default: '4rem', [XL_TO_XXL]: '4.5rem', [FROM_XXL]: '5.25rem' },
  lh10: { default: '4.5rem', [XL_TO_XXL]: '5rem', [FROM_XXL]: '5.75rem' },
  lh11: { default: '5.25rem', [XL_TO_XXL]: '5.75rem', [FROM_XXL]: '6.5rem' },
  lh12: { default: '5.75rem', [XL_TO_XXL]: '6.5rem', [FROM_XXL]: '7.5rem' },
  lh13: { default: '6.75rem', [XL_TO_XXL]: '7.5rem', [FROM_XXL]: '8.5rem' },
  lh14: { default: '7.5rem', [XL_TO_XXL]: '8.5rem', [FROM_XXL]: '9.75rem' },
  lh15: { default: '8.5rem', [XL_TO_XXL]: '9.5rem', [FROM_XXL]: '11rem' },
  // lh16: { default: '9.75rem', [XL_TO_XXL]: '11rem', [FROM_XXL]: '12.75rem' },
  // lh17: { default: '11.25rem', [XL_TO_XXL]: '12.5rem', [FROM_XXL]: '14.5rem' },
  // lh18: { default: '12.75rem', [XL_TO_XXL]: '14.25rem', [FROM_XXL]: '16.5rem' },
  // lh19: { default: '14.5rem', [XL_TO_XXL]: '16.25rem', [FROM_XXL]: '19rem' },
  // lh20: { default: '16.5rem', [XL_TO_XXL]: '18.5rem', [FROM_XXL]: '21.5rem' },
  // lh21: { default: '19rem', [XL_TO_XXL]: '21.25rem', [FROM_XXL]: '24.75rem' },
  // lh22: { default: '21.75rem', [XL_TO_XXL]: '24.25rem', [FROM_XXL]: '28.25rem' },
  // lh23: { default: '24.75rem', [XL_TO_XXL]: '28rem', [FROM_XXL]: '32.5rem' },
  // lh24: { default: '28.5rem', [XL_TO_XXL]: '32rem', [FROM_XXL]: '37.25rem' },
  // lh25: { default: '32.5rem', [XL_TO_XXL]: '36.5rem', [FROM_XXL]: '42.5rem' },
  // lh26: { default: '37.25rem', [XL_TO_XXL]: '42rem', [FROM_XXL]: '48.75rem' },
  // lh27: { default: '42.75rem', [XL_TO_XXL]: '48rem', [FROM_XXL]: '56rem' },
  // lh28: { default: '49rem', [XL_TO_XXL]: '55.25rem', [FROM_XXL]: '64.25rem' },
  // lh29: { default: '56.25rem', [XL_TO_XXL]: '63.25rem', [FROM_XXL]: '73.75rem' },
  // lh30: { default: '64.5rem', [XL_TO_XXL]: '72.5rem', [FROM_XXL]: '84.5rem' },
});

export const globalTokens = stylex.defineVars({
  space: '0.25rem',

  // fontMono: [
  //   'ui-monospace',
  //   'Menlo',
  //   'Monaco',
  //   '"Cascadia Mono"',
  //   '"Segoe UI Mono"',
  //   '"Roboto Mono"',
  //   '"Oxygen Mono"',
  //   '"Ubuntu Monospace"',
  //   '"Source Code Pro"',
  //   '"Fira Mono"',
  //   '"Droid Sans Mono"',
  //   '"Courier New"',
  //   'monospace',
  // ].join(', '),
  fontSans: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    '"Noto Sans"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    '"Noto Color Emoji"',
  ].join(', '),
});

export const scale = stylex.defineVars({
  shrink: 'scale(0.95)',
  base: 'scale(1)',
  grow: 'scale(1.2)',
});
