'use client';

import * as sx from '@stylexjs/stylex';
import Link from 'next/link';
import React from 'react';

import { globalTokens as $, color, type } from '@/app/tokens.stylex';
import { languages } from '@/lib/constants';

import type { Language, Section } from '@/lib/constants';

const FROM_S = '@media screen and (min-width: 37.5em)';
const UNTIL_S = '@media screen and (max-width: 37.4375em)';
const s = sx.create({
  wrapper: {
    '--brdr': {
      // eslint-disable-next-line @stylexjs/valid-styles
      default: `rgba(${color.accentB},0.3)`,
      // eslint-disable-next-line @stylexjs/valid-styles
      ':focus-within': `rgb(${color.accentB})`,
      // eslint-disable-next-line @stylexjs/valid-styles
      ':hover': `rgb(${color.accentB})`,
    },
    insetInlineEnd: {
      [UNTIL_S]: 'var(--hdr-gutter)',
      default: null,
    },
    marginInlineStart: 'auto',
    opacity: {
      default: 0.7,
      ':focus-within': 0.9,
      ':hover': 0.9,
    },
    position: {
      [FROM_S]: 'relative',
      default: 'absolute',
    },
    top: {
      [UNTIL_S]: 'var(--hdr-top-padding)',
      default: null,
    },
    transition: 'all 0.25s ease-in-out',
    zIndex: 1,
  },
  visHidden: {
    borderWidth: '0',
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    margin: '0 -1px -1px 0',
    opacity: 0,
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
    wordWrap: 'normal',
  },
  btn: {
    cursor: 'pointer',
    lineHeight: type.lh0,
    outlineWidth: '0px',
    paddingInline: {
      [FROM_S]: `calc(${$.space} * 4)`,
      default: `calc(${$.space} * 2)`,
    },
  },
  BtnMenuShared: {
    backgroundColor: `rgb(${color.surface1})`,
    borderColor: 'var(--brdr)',
    borderRadius: '15px',
    borderStyle: 'solid',
    borderWidth: '1px',
    fontSize: type.fzMin1,
    transition: 'all 0.25s ease-in-out',
  },
  btnContent: {
    alignItems: 'center',
    columnGap: `calc(${$.space} * 2)`,
    display: 'inline-flex',
    lineHeight: 1,
    position: 'relative',
    zIndex: 1,
  },
  btnText: {
    transform: 'translateY(-0.07em)',
  },
  menu: {
    fontSize: type.fzMin2,
    lineHeight: type.lhMin1,
    marginTop: `calc(${$.space} * 1)`,
    overflow: 'hidden',
    width: '100%',
  },
  link: {
    backgroundColor: {
      default: null,
      ':hover': '#fff',
      ':focus': '#fff',
    },
    color: {
      default: null,
      ':hover': `rgb(${color.accentB})`,
      ':focus': `rgb(${color.accentB})`,
    },
    display: 'block',
    outlineWidth: '0px',
    paddingInline: {
      [FROM_S]: `calc(${$.space} * 4)`,
      default: `calc(${$.space} * 2)`,
    },
    textDecoration: 'underline',
    textDecorationColor: {
      default: 'transparent',
      ':hover': `rgb(${color.accentB})`,
      ':focus': `rgb(${color.accentB})`,
    },
    textDecorationStyle: 'solid',
    textDecorationThickness: {
      default: '0px',
      ':hover': '1px',
      ':focus': '1px',
    },
    transition: 'all 0.25s ease-in-out',
  },
  separator: {
    backgroundColor: `rgba(${color.accentB},0.1)`,
    height: '1px',
  },
});

type LangsMap = {
  [key in Language]: string;
};
const langMap: LangsMap = {
  en: 'English',
  el: 'Ελληνικά',
  es: 'Español',
  it: 'Italiano',
  sv: 'Svenska',
  ca: 'Català',
  fr: 'Français',
};

interface Props {
  children: React.ReactNode;
  lang: Language;
  section: Section;
}

function handleKeyDown(evt: React.KeyboardEvent) {
  if (evt.key === 'Escape') {
    const dialog = evt.currentTarget as HTMLDialogElement;
    dialog.close();
  }
}
export default function LangSwitcher({ children, lang, section }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const menuRef = React.useRef<HTMLDialogElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const shouldFocusBtn = React.useRef<boolean>(false);
  const clickShouldOpen = React.useRef<boolean>(true);

  function onBtnClick(_evt: React.MouseEvent) {
    if (clickShouldOpen.current) {
      setIsOpen(!isOpen);
      shouldFocusBtn.current = true;
    } else clickShouldOpen.current = true;
  }

  function onClose() {
    const btn = document.getElementById('langBtn');
    setIsOpen(false);
    if (shouldFocusBtn.current && btn) btn?.focus();
  }

  function handleClickOutside(evt: MouseEvent) {
    const wrapper = wrapperRef.current;
    const btn = btnRef.current;
    const clickedElement = evt.target as HTMLElement;

    if (wrapper && !wrapper.contains(clickedElement) && clickedElement !== btn) {
      shouldFocusBtn.current = false;
      setIsOpen(false);
    }
  }

  const onLoseFocus = (evt: React.FocusEvent) => {
    const targetElement = evt.relatedTarget as HTMLElement;
    if (targetElement && !menuRef.current?.contains(targetElement)) {
      shouldFocusBtn.current = false;
      setIsOpen(false);
    }

    if (targetElement === btnRef.current) clickShouldOpen.current = false;
  };

  React.useEffect(() => {
    if (isOpen) document.addEventListener('click', handleClickOutside);
    else document.removeEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  if (isOpen) menuRef.current?.show();

  const otherLangs: React.ReactNode[] = [];
  for (const l of languages) {
    if (l !== lang) {
      otherLangs.push(
        <React.Fragment key={l}>
          {
            /* Don't render seperator above first Item */
            otherLangs.length ? <div {...sx.props(s.separator)} aria-hidden /> : null
          }
          <Link href={`/${l}/${section}`} lang={l} {...sx.props(s.link)}>
            <span {...sx.props(s.visHidden)}>Switch to: {langMap[l]}</span>
            <span aria-hidden>{l}</span>
          </Link>
        </React.Fragment>
      );
    }
  }

  return (
    <div {...sx.props(s.wrapper)} ref={wrapperRef} onBlur={onLoseFocus}>
      <button
        ref={btnRef}
        aria-expanded={isOpen}
        aria-haspopup
        aria-label="Switch language"
        aria-controls="langMenu"
        onClick={onBtnClick}
        {...sx.props(s.BtnMenuShared, s.btn)}
      >
        <span {...sx.props(s.btnContent)}>
          <span {...sx.props(s.visHidden)}>Current language: {langMap[lang]}</span>
          <span aria-hidden {...sx.props(s.btnText)}>
            {lang}
          </span>
          {children}
        </span>
      </button>
      <dialog
        ref={menuRef}
        open={isOpen}
        // open
        onClose={onClose}
        onKeyDown={handleKeyDown}
        {...sx.props(s.BtnMenuShared, s.menu)}
      >
        {otherLangs}
      </dialog>
    </div>
  );
}
